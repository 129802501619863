<template>
  <v-card
    :color="selected ? 'tertiary' : 'backgroundCard'"
    class="my-3"
    :height="height"
  >
    <v-container
      fill-height
      fluid
    >
      <v-row
        align="center"
        class="text-left"
        :class="selected ? 'selected' :''"
      >
        <v-col
          cols=11
          class="pr-0"
        >
          {{ item.text }}
        </v-col>
        <v-col
          v-if="selected"
          cols=1
          class="pa-0"
        >
          <v-icon
            color=primary
          >
            mdi-check-circle
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChoiceText',
  props: {
    item: Object,
  },
  computed: {
    ...mapGetters('testMicro', [
      'selectedIndex',
    ]),
    selected() {
      return this.selectedIndex === this.item.index;
    },
    height() {
      const textLength = this.item.text.length;
      const isSmAndDown = this.$vuetify.breakpoint.smAndDown;
      if (textLength >= 150 && isSmAndDown) {
        return '150';
      }
      if (textLength >= 110 && isSmAndDown) {
        return '125';
      }
      return '100';
    },
  },
};
</script>

<style scoped>
.selected {
  font-weight: bold;
}
</style>
